import React from 'react'
import { Link } from 'gatsby'
import './BlogPostEntry.scss'

const BlogPostEntry = ({ post: { data, uid } }) => {
  const title = data.title.raw[0].text
  const blurb = data.blurb.html && data.blurb.raw[0].text

  return (
    <article className="BlogPostEntry">
      <h2>
        <Link to={`/blog/${uid}`}>{title}</Link>
      </h2>
      {blurb && <p className="BlogPostEntry_blurb">{blurb}</p>}
      <header>
        <span className="BlogPostEntry_published">{data.publish_date}</span>
      </header>
    </article>
  )
}

export default BlogPostEntry
