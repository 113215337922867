import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BlogPostEntry from '../components/BlogPostEntry'
import { useScrollTop } from '../utils'
import './blog.scss'

const Blog = ({
  pageContext: {
    posts: {
      allPrismicBlogPost: { nodes: posts },
    },
  },
}) => {
  useScrollTop()

  return (
    <Layout>
      <Seo title="Blog" />
      <section className="Blog">
        {posts.map(post => (
          <BlogPostEntry post={post} key={post.uid} />
        ))}
      </section>
    </Layout>
  )
}

export default Blog
